import { UserAvatar } from '@/components/user-avatar';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const ConfirmUser = (props: any) => {
  const styles = useStyles()
  const { t } = useTranslation()

  const { userInfo } = props

  return (
    <div className={styles.confirmUser}>
      <div className={styles.confirmUserAvatar}>
        <UserAvatar
          user={userInfo}
        />
      </div>

      <div className={styles.confirmUserName}>{userInfo?.name}</div>
      <div className={styles.confirmUserCompany}>
        {userInfo?.companyName}
      </div>
      <div className={styles.confirmUserTitle}>
        {userInfo?.companyTitle}
      </div>
    </div>
  )
}

export default ConfirmUser;