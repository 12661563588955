import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  //alert
  alertModal: {
    display: 'flex',
    minWidth: '400px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      minWidth: '250px',
    },
  },

  successImg: {
    display: 'flex',
    width: '250px',
    marginBottom: '15px',
    '@media (max-width: 768px)': {
      width: '135px',
    },
  },

  successTitle: {
    color: Colors.PRIMARY,
    fontSize: '20px',
    fontWeight: '500',
  },

  successDescription: {
    color: '#000',
    fontSize: '14px',
    fontWeight: '400',
    marginTop: '15px',
  },

  groupBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '15px',
  },

  btnOk: {
    width: '65%',
    height: '40px',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: Colors.PRIMARY,
    color: '#fff',
    marginTop: '15px',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
    '&:hover': {
      backgroundColor: Colors.PRIMARY,
      color: '#fff',
    },
  },

  btnCancel: {
    width: '65%',
    height: '40px',
    border: `1px solid ${Colors.PRIMARY}`,
    borderRadius: '8px',
    backgroundColor: '#fff',
    color: Colors.PRIMARY,
    marginTop: '15px',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
    '&:hover': {
      backgroundColor: '#fff',
      color: Colors.PRIMARY,
    },
  },
})

export default useStyles
