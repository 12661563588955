const images = {
  eventDefault: () => require('./images/event_default.png'),
  memberDefault: () => require('./images/member_default.png'),
  eventSuggestDefault: () => require('./images/event_suggest_default.png'),
  eventDetailDefault: () => require('./images/event_detail_default.png'),
  icInvalid: () => require('./images/ic_invalid.png'),
  icValid: () => require('./images/ic_valid.png'),
  profileCard1: () => require('./images/profile_card_1.png'),
  outOfTicket: () => require('./images/out_of_ticket.png'),
  orderSuccess: () => require('./images/order_success.png'),
  success: () => require('./images/success.png'),
  failed: () => require('./images/failed.png'),
  dataNotFound: () => require('./images/data_not_found.png'),
  icMessenger: () => require('./images/icons/ic_messenger.png'),
  icFacebook: () => require('./images/icons/ic_facebook.png'),
  icInstagram: () => require('./images/icons/ic_instagram.png'),
  icSms: () => require('./images/icons/ic_sms.png'),
  icEmail: () => require('./images/icons/ic_email.png'),
  icCopy: () => require('./images/icons/ic_copy.png'),
  icMore: () => require('./images/icons/ic_more.png'),
  icParticipant: () => require('./images/icons/ic_participant.png'),
  icLocation: () => require('./images/icons/ic_location.png'),
  icCalendar: () => require('./images/icons/ic_calendar.png'),
  icGender: () => require('./images/icons/ic_gender.png'),
  icAge: () => require('./images/icons/ic_age.png'),
  icScan: () => require('./images/icons/ic_scan.png'),
}

export default images