import moment from 'moment'
import useStyles from "./styles"

export const ParticipantCard = (props: any) => {
  const { participant } = props
  const styles = useStyles()

  return (
    <div className={styles.participantCard}>
      <div className={styles.participantName}>
        {participant?.user?.name}
      </div>

      <div className={styles.participantCompany}>
        {participant?.user?.company_name}
      </div>

      <div className={styles.participantTitle}>
        {participant?.user?.company_title}
      </div>

      <div className={styles.participantChecked}>
        <span>{participant?.updatedAt ? moment(participant?.updatedAt).format('DD/MM/YYYY HH:mm') : ''}</span>
      </div>
    </div>
  )
}