import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  svgContainerStyle: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Màu overlay
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },

  svgDesc: {
    color: '#fff',
    fontSize: '14px',
    zIndex: 1,
    marginBottom: '36px',
    fontWeight: '400',
  },

  svgStyle: {
    top: '0px',
    left: '0px',
    zIndex: 1,
    width: '35%',
    objectFit: 'none',
    '@media (max-width: 768px)': {
      width: '65%',
    },
  },
})

export default useStyles
