import { Loading } from '@/components/loading'
import { RoutesUrl } from '@/constants'
import { setLoading } from '@/stores/event/service'
import gateway from '@/stores/gateway'
import { UserAPI } from '@/stores/user'
import { saveUser } from '@/stores/user/api'
import { localStorageUtils } from '@/utils'
import { LocalStorageValueTypes } from '@/utils/localStorage'
import i18next from 'i18next'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useStyles from "./styles"

interface WrapperProps {
  children: React.ReactNode
}

export const Unauthorized = (props: WrapperProps) => {
  const navigate = useNavigate()
  const { children } = props
  const styles = useStyles()

  useEffect(() => {
    const lang = localStorageUtils.getLocalStorage(LocalStorageValueTypes.UserLanguage)
    const currentLang = lang ? lang : 'en'
    i18next.changeLanguage(currentLang)
    if (!lang) {
      localStorageUtils.setLocalStorage(LocalStorageValueTypes.UserLanguage, currentLang)
    }

    checkAuthorized()
  }, [])

  const checkAuthorized = async () => {
    const isLogin = await authLogin()

    if (isLogin) {
      navigate(RoutesUrl.Home)
      return true
    }
  }

  const authLogin = async () => {
    const token = localStorageUtils.getLocalStorage(
      LocalStorageValueTypes.AccessToken
    )
    if (!token) return false

    gateway.init(JSON.parse(token))
    const response: any = await UserAPI.getUserProfile()
    const { data } = response

    if (data?.isSuccess) {
      saveUser(data?.data)
    }

    setLoading(false)
    return data?.isSuccess
  }

  const isLoading = false
  if (isLoading) return <Loading />

  return (
    <div className={styles.wrapper}>
      {children}
    </div>
  )
}
