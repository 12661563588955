import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { UserState } from './model'
import { changeUser, setLoading } from './service'

const useUserStore = create<UserState>()(
  devtools(immer((set, get) => ({
    user: null,
    authLoading: false,
    setLoading,
    changeUser,
  })))
)

export default useUserStore
