import { useModal } from '@/ModalContext';
import { Wrapper } from '@/components';
import Header from '@/components/wrapper/components/header';
import { RoutesUrl } from '@/constants';
import { EventAPI } from '@/stores/event';
import EventTicketModel from '@/stores/event/event-ticket/model';
import EventModel from '@/stores/event/model';
import useEventStore from '@/stores/event/state';
import { UserAPI } from '@/stores/user';
import UserModel from '@/stores/user/model';
import useUserStore from '@/stores/user/state';
import { modalRef } from '@/utils/refs';
import { QrScanner } from '@yudiel/react-qr-scanner';
import clsx from 'clsx';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import AlertModal from './components/alert-modal';
import ConfirmUser from './components/confirm-user';
import EnterCode from './components/enter-code';
import { ScanOverlay } from './components/scan-overlay';
import useStyles from './styles';

const CheckIn = () => {
  const { t } = useTranslation()
  const styles = useStyles()
  const navigate = useNavigate()
  const { showModal, hideModal } = useModal()

  const [scan, setScan] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)

  const { user } = useUserStore()
  const { event, setEvent, eventTicket, setEventTicket } = useEventStore()

  const params = useParams()
  const { id } = params

  useEffect(() => {
    if (!user) {
      navigate(RoutesUrl.SignIn)
    }
  }, [user])

  useEffect(() => {
    if (user && id) {
      getEvent(id)
    }
  }, [user, id])

  const getEvent = async (eventId: string) => {
    setLoading(true)
    const response: any = await EventAPI.getEvent(eventId)
    if (response.status === 200) {
      const newEvent = new EventModel(response.data.data)
      setEvent(newEvent)
    }
    setLoading(false)
  }

  const getEventTicket = async (eventId: string, ticketId: string) => {
    const response = await EventAPI.getEventTicket(eventId, ticketId)
    if (response?.data?.status === 200) {
      const newEventTicket = new EventTicketModel(response.data.data)
      setEventTicket(newEventTicket)
    }
  }

  const callback = () => {
    setScan(true)
  }

  const onCancelConfirm = () => {
    callback()
    hideModal()
  }

  const onContinueConfirm = (params: any) => {
    hideModal()
    confirmCheckIn(params)
  }

  const confirmUserInfo = async (params: any) => {
    const { qrUserId } = params
    const res = await UserAPI.getUserDetail(qrUserId)
    if (res?.status !== 200) {
      onShowAlertModal(false, t('checkIn.check_in_failed'), '', callback)
      return
    }

    const userInfo = new UserModel(res?.data?.data)

    showModal(
      <ConfirmUser userInfo={userInfo} callback={callback} />,
      <div className={styles.btnGroupConfirm}>
        <button className={clsx('btn', styles.btnCancel)} onClick={onCancelConfirm}>{t('alert.cancel')}</button>
        <button className={clsx('btn', styles.btnOk)} onClick={() => onContinueConfirm(params)}>{t('alert.continue')}</button>
      </div>,
      t('checkIn.confirm_info')
    )
  }

  const confirmCheckIn = async (params: any) => {
    const { qrEventId, qrTicketId } = params

    setScan(false)
    let isSuccess = false
    let message = ''
    let check_in_id = ''

    const data = {
      eventId: qrEventId,
      participantId: qrTicketId,
      status: 2,
    }

    const response = await EventAPI.checkIn(data)
    if (response?.status === 200) {
      isSuccess = true
      message = t('checkIn.check_in_success')
      check_in_id = response?.data?.data?.check_in_id
    } else {
      isSuccess = false
      message = response?.data?.data?.message || t('checkIn.check_in_failed')
    }
    onShowAlertModal(isSuccess, message, check_in_id, callback)
  }

  const handleScan = async (qrValues: any) => {
    console.log(qrValues)
    if (qrValues && event?.id) {
      const ticketId = qrValues?.replace(/"/g, '') || ''
      const isSuccess = false
      let message = t('checkIn.check_in_failed')
      const check_in_id = ''

      try {
        if (event?.eventDateTimes?.length === 0) {
          onShowAlertModal(isSuccess, message, check_in_id, callback)
          return
        }

        const eventStartDateTime = event?.eventDateTimes[0].startDateTime ? moment(event?.eventDateTimes[0].startDateTime).subtract(2, 'hours').format('YYYY-MM-DD HH:mm') : ''
        const eventEndDateTime = event?.eventDateTimes[event?.eventDateTimes?.length - 1].endDateTime ? moment(event?.eventDateTimes[event?.eventDateTimes?.length - 1].endDateTime).format('YYYY-MM-DD HH:mm') : ''
        const currentDateTime = moment().format('YYYY-MM-DD HH:mm')
        console.log("star", eventStartDateTime)
        console.log("end", eventEndDateTime)
        console.log("current", currentDateTime)

        if (moment(currentDateTime).isBefore(eventStartDateTime)) {
          message = t('checkIn.event_not_start')
          onShowAlertModal(isSuccess, message, check_in_id, callback)
          return
        }

        if (moment(currentDateTime).isAfter(eventEndDateTime)) {
          message = t('checkIn.event_expired')
          onShowAlertModal(isSuccess, message, check_in_id, callback)
          return
        }

        const response = await EventAPI.getEventTicket(event?.id, ticketId)
        if (response?.data?.status === 200) {
          const newEventTicket = new EventTicketModel(response.data.data)

          console.log("newEventTicket", newEventTicket)

          const qrEventId = newEventTicket?.eventId || ''
          const qrTicketId = newEventTicket?.id || ''
          const qrUserId = newEventTicket?.userId || ''

          if (qrEventId !== event?.id) {
            message = t('checkIn.qr_code_not_for_this_event')
            onShowAlertModal(isSuccess, message, check_in_id, callback)
            return
          }

          return confirmUserInfo({ qrUserId, qrEventId, qrTicketId })
        } else {
          message = response?.data?.data?.message || t('checkIn.check_in_failed')
          onShowAlertModal(isSuccess, message, check_in_id, callback)
          return
        }
      } catch (error) {
        onShowAlertModal(false, t('checkIn.check_in_failed'), '', callback)
      }
    } else {
      onShowAlertModal(false, t('checkIn.check_in_failed'), '', callback)
    }
  }

  const onShowAlertModal = (isSuccess: boolean, message: string, check_in_id: string, callback: () => void) => {
    modalRef.current?.open({
      content: <AlertModal isSuccess={isSuccess} message={message} check_in_id={check_in_id} callback={callback} />,
    })
  }

  const showInput = () => {
    modalRef.current?.open({
      content: <EnterCode />,
    })
  }

  return (
    <Wrapper>
      <Header
        title='Check in'
        showBack={true}
        // right={
        //   <div className={styles.headerRight}>
        //     <div className={styles.headerRightText} onClick={showInput}>{t('checkIn.enterCode')}</div>
        //   </div>
        // }
      />

      <div className={styles.qrScannerContent}>
        <div className={styles.qrReaderContainer}>
          <QrScanner
            constraints={{ facingMode: 'environment' }}
            scanDelay={100}
            onDecode={!scan ? undefined : handleScan}
            onError={(error: any) => console.log(error?.message)}
            containerStyle={{
              width: '100%',
              height: '100%',
            }}
            viewFinder={ScanOverlay}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default CheckIn;
