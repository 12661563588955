import useStyles from "./styles"

export const Loading = () => {
  const styles = useStyles()

  return (
    <div className={styles.loadingOverlay}>
      <div className={styles.loadingSpinner}></div>
    </div>
  )
}
