import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  participantCard: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '15px',
    marginBottom: '15px',
    borderRadius: '10px',
    boxShadow: '-1px 1px 16px 0px rgba(0, 0, 0, 0.08)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FDA7871A',
    },
  },

  participantName: {
    display: 'flex',
    fontSize: '0.875rem',
    fontWeight: '500',
    color: Colors.PRIMARY,
    marginBottom: '5px',
  },

  participantCompany: {
    display: 'flex',
    fontSize: '0.875rem',
    fontWeight: '500',
    color: '#131313',
    marginBottom: '5px',
  },

  participantEmail: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '0.75rem',
    fontWeight: '400',
    color: '#131313',
    '& span': {
      marginLeft: '5px',
    },
  },
})

export default useStyles
