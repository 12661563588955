import images from '@/assets';
import { modalRef } from '@/utils/refs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';
import clsx from 'clsx';

const AlertModal = (props: any) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { isSuccess, message, check_in_id, callback } = props

  const onContinue = () => {
    modalRef.current?.close()
  }

  const onTryAgain = () => {
    modalRef.current?.close()
    callback()
  }

  const onCancel = () => {
    navigate(-1)
    modalRef.current?.close()
  }

  return (
    <div className={styles.alertModal}>
      <div className={styles.successImg}>
        <img src={isSuccess ? images.success() : images.failed()} alt="success" />
      </div>

      <div className={styles.successTitle}>{isSuccess ? t('alert.success') : t('alert.failed')}</div>
      <div className={styles.successDescription}>
        {isSuccess ? t('checkIn.check_in_success') : message}
      </div>

      <button className={clsx('btn', styles.btnOk)} onClick={onTryAgain}>{isSuccess ? t('alert.ok') : t('alert.try_again')}</button>
      {!isSuccess && <button className={clsx('btn', styles.btnCancel)} onClick={onCancel}>{t('alert.cancel')}</button>}
    </div>
  )
}

export default AlertModal;