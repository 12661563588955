import { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { shallow } from 'zustand/shallow'

import NotFound from '@/containers/not-found'
import { localStorageUtils } from '@/utils'
import { LocalStorageValueTypes } from '@/utils/localStorage'
import i18next from 'i18next'
import { RoutesUrl } from './constants/routes.constants'
import CheckIn from './containers/authenticated/events/check-in'
import EventDetail from './containers/authenticated/events/event-detail'
import Home from './containers/authenticated/home'
import Settings from './containers/authenticated/settings'
import ChangeLanguage from './containers/authenticated/settings/change-language'
import ChangePassword from './containers/authenticated/settings/change-password'
import HelpCenter from './containers/authenticated/settings/help-center'
import gateway from './stores/gateway'
import { UserAPI } from './stores/user'
import UserModel, { UserState } from './stores/user/model'
import useUserStore from './stores/user/state'

function AuthenticatedApp() {
  const navigate = useNavigate()

  const [changeUser, setLoading] = useUserStore(
    (state: UserState) => [state.changeUser, state.setLoading],
    shallow
  )

  useEffect(() => {
    const lang = localStorageUtils.getLocalStorage(LocalStorageValueTypes.UserLanguage)
    const currentLang = lang ? lang : 'en'
    i18next.changeLanguage(currentLang)
    if (!lang) {
      localStorageUtils.setLocalStorage(LocalStorageValueTypes.UserLanguage, currentLang)
    }

    checkAuthorized()
  }, [])

  const saveUser = (data: any) => {
    changeUser(new UserModel(data))
    localStorageUtils.setLocalStorage(LocalStorageValueTypes.UserData, JSON.stringify(data))
  }

  const authLogin = async () => {
    const token = localStorageUtils.getLocalStorage(
      LocalStorageValueTypes.AccessToken
    )
    if (!token) return false

    gateway.init(JSON.parse(token))
    const response: any = await UserAPI.getUserProfile()
    const { data } = response

    if (data?.isSuccess) {
      saveUser(data?.data)
    }

    setLoading(false)
    return data?.isSuccess
  }

  const onLogout = async () => {
    localStorageUtils.deleteLocalStorage(LocalStorageValueTypes.AccessToken)
    localStorageUtils.deleteLocalStorage(LocalStorageValueTypes.UserData)
  }

  const checkAuthorized = async () => {
    const isLogin = await authLogin()

    if (!isLogin) {
      onLogout()
      navigate(RoutesUrl.SignIn)
      return false
    }
  }

  return (
    <Routes>
      <Route path={RoutesUrl.Home} element={<Home />} />
      <Route path={RoutesUrl.Settings} element={<Settings />} />
      <Route path={RoutesUrl.ChangeLanguage} element={<ChangeLanguage />} />
      <Route path={RoutesUrl.ChangePassword} element={<ChangePassword />} />
      <Route path={RoutesUrl.HelpCenter} element={<HelpCenter />} />
      <Route path={RoutesUrl.EventDetail} element={<EventDetail />} />
      <Route path={RoutesUrl.CheckIn} element={<CheckIn />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default AuthenticatedApp
