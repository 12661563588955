export enum RoutesUrl {
  Home = '/',
  SignIn = '/sign-in',
  SignUp = '/sign-up',
  ForgotPassword = '/forgot-password',
  Events = '/events',
  EventDetail = '/events/:id',
  Profile = '/profile',
  UpdateProfile = '/profile/update',
  QrScanner = '/profile/qr-scanner',
  Activity = '/activity',
  Settings = '/settings',
  EventOrder = '/events/:id/join',
  EventTicket = '/events/:eventId/ticket/:id',
  Discover = '/discover',
  Search = '/discover/search',
  ViewAll = '/events/view-all',
  EventPriority = '/events/priority',
  EventNearest = '/events/nearest',
  EventMusic = '/events/music',
  EventArt = '/events/art',
  EventWorkshop = '/events/workshop',
  EventExhibition = '/events/exhibition',
  ChangeLanguage = '/settings/change-language',
  ChangePassword = '/settings/change-password',
  HelpCenter = '/settings/help-center',
  TermsOfService = '/terms-of-service',
  PrivacyPolicy = '/privacy-policy',
  UserContact = '/profile/contacts',
  ContactDetail = '/profile/contacts/:id',
  PublicProfile = '/contacts/:id',
  CheckIn = '/events/:id/check-in',
}