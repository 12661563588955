export enum LocalStorageValueTypes {
  AccessToken = '@VHG_ACCESS_TOKEN',
  UserData = '@VHG_USER_DATA',
  UserLanguage = '@VHG_USER_LANGUAGE',
}

export const setLocalStorage = (name: LocalStorageValueTypes, value: string) => {
  window.localStorage.setItem(name, value)
}

export const getLocalStorage = (name: LocalStorageValueTypes) => {
  return window.localStorage.getItem(name)
}

export const deleteLocalStorage = (name: LocalStorageValueTypes) => {
  window.localStorage.removeItem(name)
}

export const clear = () => {
  window.localStorage.removeItem(LocalStorageValueTypes.AccessToken)
  window.localStorage.removeItem(LocalStorageValueTypes.UserData)
  window.localStorage.removeItem(LocalStorageValueTypes.UserLanguage)
}
