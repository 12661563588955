
import images from '@/assets'
import { Loading, Wrapper } from '@/components'
import Header from '@/components/wrapper/components/header'
import { RoutesUrl } from '@/constants'
import { EventAPI } from '@/stores/event'
import EventModel from '@/stores/event/model'
import useEventStore from '@/stores/event/state'
import useUserStore from '@/stores/user/state'
import { clsx } from 'clsx'
import moment from 'moment'
import { useEffect, useState } from 'react'
import "react-datepicker/dist/react-datepicker.css"
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ParticipantCard } from './participant-card'
import useStyles from './styles'

const EventDetail = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const styles = useStyles()

  const params = useParams()
  const { id } = params

  const [loading, setLoading] = useState<boolean>(false)

  const { user } = useUserStore()
  const { event, participants, setEvent, setParticipants } = useEventStore()

  useEffect(() => {
    if (user && id) {
      getEvent(id)
      getParticipants(id)
    }
  }, [user, id])

  const getEvent = async (eventId: string) => {
    setLoading(true)
    const response: any = await EventAPI.getEvent(eventId)
    if (response.status === 200) {
      const newEvent = new EventModel(response.data.data)
      setEvent(newEvent)
    }
    setLoading(false)
  }

  const getParticipants = async (eventId: string) => {
    const params = {
      eventId,
      statuses: '2'
    }
    const response: any = await EventAPI.getParticipants(params)
    if (response.status === 200) {
      setParticipants(response.data.data)
    }
  }

  const onOpenQRScanner = () => {
    if (event?.id) {
      navigate(RoutesUrl.CheckIn.replace(':id', event?.id))
    }
  }

  const eventDate = event?.eventDateTimes.find((eventDateTime: any) => moment(eventDateTime.startDateTime).isSameOrAfter(moment()))

  return (
    <Wrapper>
      <Header
        title={t('events.event_gate')}
        showBack={true}
        right={
          <div className={styles.headerRight}>
            <div className={styles.headerRightItem} onClick={onOpenQRScanner}>
              <img className={styles.qrCode} src={images.icScan()} />
            </div>
          </div>
        }
      />
      {loading && <Loading />}

      <div className={styles.container}>
        <div className={styles.ticket}>

          <div className={styles.ticketInfo}>
            <div className={styles.eventBanner}>
              <img className={styles.banner} src={event?.banner?.getRawUrl()} />
            </div>

            <div className={styles.eventInfo}>
              <div className={styles.eventName}>
                <span>{event?.title}</span>
              </div>
              <div className={styles.groupInfo}>
                <div className={styles.eventDateTime}>
                  <div className={styles.eventDate}>
                    <label className={clsx(styles.label)}>{t('viewTicket.event_date')}</label>
                    <div className={clsx(styles.value)}>{eventDate?.startDateTime ? moment(eventDate?.startDateTime).format('dddd, MMMM D, YYYY') : ''}</div>
                  </div>

                  <div className={styles.eventTime}>
                    <label className={clsx(styles.label)}>{t('viewTicket.event_time')}</label>
                    <div className={clsx(styles.value)}>
                      {eventDate?.startDateTime ? moment(eventDate?.startDateTime).format('HH:mm') : ''}
                      {eventDate?.endDateTime ? '-' + moment(eventDate?.endDateTime).format('HH:mm') : ''}
                    </div>
                  </div>

                  <div className={styles.eventLocation}>
                    <label className={clsx(styles.label)}>{t('viewTicket.event_location')}</label>
                    <div className={clsx(styles.value)}>{event?.location?.address}</div>
                  </div>
                </div>

                <div className={styles.btnCheckIn} onClick={onOpenQRScanner}>
                  <img src={images.icScan()} />
                  <div className={styles.txtScan}>{t('checkIn.scan_to_check_in')}</div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.participants}>
            <div className={styles.participantsTitle}>
              <div className={styles.title}>{t('eventDetail.attended')}</div>
              <div className={styles.number}>{participants?.length}</div>
            </div>

            <div className={styles.participantsList}>
              {participants?.map((participant: any) => (
                <ParticipantCard participant={participant} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default EventDetail