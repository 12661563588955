import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  confirmUser: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '300px',
    '@media (max-width: 768px)': {
      minWidth: '250px',
    },
  },

  confirmUserAvatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '10px',
  },

  confirmUserName: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },

  confirmUserCompany: {
    fontSize: '16px',
    marginBottom: '10px',
  },

  confirmUserTitle: {
    fontSize: '16px',
    marginBottom: '10px',
  },
})

export default useStyles
