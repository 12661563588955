import './App.css'
import './locale'

import { Route, Routes } from 'react-router-dom'

import { useEffect } from 'react'
import Authenticated from './Authenticated'
import { RoutesUrl } from './constants'
import NotFound from './containers/not-found'
import ForgotPassword from './containers/unauthenticated/forgot-password'
import SignIn from './containers/unauthenticated/sign-in'
import i18next from './locale'
import { localStorageUtils } from './utils'
import { LocalStorageValueTypes } from './utils/localStorage'

function App() {
  useEffect(() => {
    const lang = localStorageUtils.getLocalStorage(LocalStorageValueTypes.UserLanguage)
    const currentLang = lang ? lang : 'en'
    i18next.changeLanguage(currentLang)
    if (!lang) {
      localStorageUtils.setLocalStorage(LocalStorageValueTypes.UserLanguage, currentLang)
    }
  }, [])

  return (
    <Routes>
      <Route path={RoutesUrl.SignIn} element={<SignIn />} />
      <Route path={RoutesUrl.ForgotPassword} element={<ForgotPassword />} />
      <Route path="/*" element={<Authenticated />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default App
