import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  enterCode: {
    display: 'flex',
    flexDirection: 'column',
  },

  title: {
    textTransform: 'uppercase',
    fontWeight: 500,
    marginBottom: '15px',
  },

  label: {
    fontSize: '0.8rem',
    fontWeight: '400',
    color: '#858585',
  },

  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },

  input: {
    display: 'flex',
    fontSize: '1rem',
    fontWeight: '400',
    color: '#131313',
    height: '40px',
    width: '100%',
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: '1px solid #EBE9E8',
    outline: 'none',
    borderRadius: 'unset',
    '&:focus': {
      borderBottom: `1px solid ${Colors.PRIMARY}`,
    },
    '&:disabled': {
      backgroundColor: '#ffffff',
      cursor: 'not-allowed',
    },
  },

  btnGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '30px',
  },

  btnUpdate: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '45px',
    fontSize: '16px',
    fontWeight: '500',
    color: Colors.WHITE,
    backgroundColor: Colors.PRIMARY,
    borderRadius: '8px',
    cursor: 'pointer',
    width: '100%',
    '&:hover': {
      color: Colors.WHITE,
      backgroundColor: Colors.PRIMARY,
      cursor: 'pointer',
    },
  },

  btnDisabled: {
    backgroundColor: Colors.GRAY,
    color: Colors.WHITE,
    cursor: 'not-allowed',
    '&:hover': {
      color: Colors.WHITE,
      backgroundColor: Colors.GRAY,
      cursor: 'not-allowed',
    },
  },

})

export default useStyles
