import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  eventItem: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '10px',
    marginBottom: '15px',
    borderRadius: '10px',
    boxShadow: '0px 0px 18px -3px #0000001A',
  },

  eventSuggestCard: {
    display: 'flex',
    width: '100%',
  },

  eventSuggestBanner: {
    width: '90px',
    height: '86px',
    borderRadius: '8px',
    backgroundColor: Colors.WHITE,
    '& img': {
      width: '90px',
      height: '86px',
      borderRadius: '8px',
      objectFit: 'cover',
    },
  },

  eventSuggestContent: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '10px',
    width: 'calc(100% - 90px)',
  },

  rowSuggestTitle: {
    display: 'flex',
    fontWeight: '600',
  },

  eventSuggestTitle: {
    flex: '1',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  // eventJoined
  eventJoined: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    paddingTop: '5px',
  },

  eventTime: {
    display: 'flex',
    fontSize: '13px',
    fontWeight: '400',
    color: '#F84C0B'
  },

  eventLocation: {
    display: 'flex',
    fontSize: '13px',
    fontWeight: '400',
    width: '100%',
    marginTop: '5px',
    '@media (max-width: 768px)': {
      width: '160px',
    }
  },

  address: {
    display: 'flex',
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#8A8380',
    marginLeft: '5px',
  },

  rowSuggestAction: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  rowPrice: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#FDA78733',
    borderRadius: '5px',
    marginTop: '10px',
    marginLeft: '5px',
  },

  txtPrice: {
    fontSize: '12px',
    padding: '6px 12px',
    color: Colors.PRIMARY,
    fontWeight: '600',
    whiteSpace: 'nowrap',
  },

  rowPriceJoined: {
    backgroundColor: '#27AE601A',
  },

  txtPriceJoined: {
    color: '#27AE60',
  },

  viewTicket: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '15px',
    paddingTop: '15px',
    paddingBottom: '5px',
    borderTop: '1px solid #EBE9E8',
  },

  btnTicket: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60%',
    height: '40px',
    borderRadius: '10px',
    backgroundColor: Colors.PRIMARY,
    color: Colors.WHITE,
    fontWeight: '600',
    fontSize: '12px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: Colors.PRIMARY,
    }
  },
})

export default useStyles
