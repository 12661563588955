export default {
  english: 'Tiếng Anh',
  vietnamese: 'Tiếng Việt',
  logout: 'Đăng xuất',
  dataNotFound: 'Không tìm thấy dữ liệu',
  home: {
    home: 'Trang chủ',
    event: 'Sự kiện',
    mine: 'Mine',
    activity: 'Hoạt động',
    settings: 'Cài đặt',
    discover: 'Khám phá',
    notFound: 'Không tìm thấy',
  },
  signIn: {
    sign_in: "Đăng nhập",
    email: "Email",
    password: "Mật khẩu",
    btn_sign_in: "Đăng nhập",
    or: "Hoặc đăng nhập với",
    facebook: "Facebook",
    twitter: "Twitter",
    sign_up: "Đăng ký ngay",
    forgot_password: 'Quên mật khẩu',
    dont_have_an_account: 'Bạn chưa có tài khoản?',
  },
  signUp: {
    sign_up: "Đăng ký",
    name: "Tên",
    phone: "Số điện thoại",
    email: "Email",
    password: "Mật khẩu",
    confirm_password: "Nhập lại mật khẩu",
    btn_sign_up: "Đăng ký",
    or: "Hoặc đăng nhập với",
    facebook: "Facebook",
    twitter: "Twitter",
    sign_in: "Đăng nhập ngay",
    have_an_account: "Bạn đã có tài khoản?",
  },
  forgotPassword: {
    forgot_password: "Quên mật khẩu",
    email: "Email",
    forgot_password_description: 'Nhập mail của bạn để nhận OTP',
    forgot_password_send_otp: 'Gửi OTP đến email',
    forgot_password_check_mail: 'Vui lòng kiểm tra OTP trong hộp thư của bạn.',
    forgot_password_expires: 'Mã OTP sẽ hết hạn sau 10 phút.',
    resend: 'Gửi lại trong',
    enter_new_password: 'Nhập mật khẩu mới',
    reset_password: 'Đặt lại mật khẩu',
    password: 'Mật khẩu',
    confirm_password: 'Nhập lại mật khẩu',
  },
  profile: {
    personal_info: 'Thông tin cá nhân',
    company_info: 'Thông tin doanh nghiệp',
    first_name: 'Tên',
    last_name: 'Họ',
    name: 'Họ tên',
    phone: 'Số điện thoại',
    email: 'Email',
    address: 'Địa chỉ',
    gender: 'Giới tính',
    age: 'Tuổi',
    company_name: 'Tên công ty',
    title: 'Chức danh',
    business_email: 'Email công ty',
    company_introduction: 'Giới thiệu công ty',
    choose_card_template: 'Chọn mẫu danh thiếp',
    btn_update: 'Cập nhật',
    male: 'Nam',
    female: 'Nữ',
    other: 'Khác',
    under: 'Dưới',
    above: 'trở lên',
    under_16: 'Dưới 16',
    from_17_30: '17 - 30',
    from_31_45: '31 - 45',
    above_45: 'Trên 45',
    update_profile: 'Cập nhật thông tin',
    business_card: 'Danh thiếp',
    contacts: 'Liên hệ',
  },
  changePassword: {
    change_password: 'Đổi mật khẩu',
    current_password: 'Mật khẩu hiện tại',
    current_password_placeholder: 'Nhập mật khẩu hiện tại',
    new_password: 'Mật khẩu mới',
    new_password_placeholder: 'Nhập mật khẩu mới',
    confirm_password: 'Nhập lại mật khẩu',
    confirm_password_placeholder: 'Nhập lại mật khẩu',
    save: 'Lưu',
    success: 'Thành công',
    change_password_success: 'Mật khẩu của bạn đã thay đổi thành công',
    change_password_failed: 'Đổi mật khẩu thất bại',
    try_again: 'Thử lại',
    ok: 'Ok',
    cancel: 'Hủy',
    current_password_required: 'Mật khẩu hiện tại là bắt buộc',
    new_password_required: 'Mật khẩu mới là bắt buộc',
    confirm_password_required: 'Nhập lại mật khẩu là bắt buộc',
    confirm_password_not_match: 'Nhập lại mật khẩu không khớp',
    new_password_min_length: 'Mật khẩu mới phải có ít nhất {{length}} ký tự',
  },
  helpCenter: {
    help_center: 'Trung tâm trợ giúp',
  },
  contactUs: {
    contact_us: 'Liên hệ',
    subject: 'Chủ đề',
    message: 'Nội dung',
    send_message: 'Gửi đi',
    we_ready_to_contact: 'Chúng tôi sẵn sàng hỗ trợ bạn',
  },
  orderDetail: {
    order_detail: 'Chi tiết đơn hàng',
    date: 'Ngày',
    attendees: 'Người tham dự',
    note: 'Ghi chú',
    share_contact_info: 'Chia sẻ thông tin liên hệ',
    name: 'Họ tên',
    phone: 'Số điện thoại',
    btn_continue: 'Tiếp tục',
  },
  alert: {
    alert: 'Thông báo',
    congratulations: 'Chúc mừng',
    join_event_success: 'Bạn đã đăng ký thành công sự kiện',
    join_event_failed: 'Đăng ký sự kiện thất bại',
    view_e_ticket: 'Xem E-Ticket',
    back_to_home: 'Quay lại trang chủ',
    continue: 'Tiếp tục',
    oops_sorry: 'Oops, Xin lỗi!',
    success: 'Thành công',
    failed: 'Rất tiếc, Thất bại!',
    ok: 'Ok',
    cancel: 'Hủy',
    try_again: 'Thử lại',
  },
  viewTicket: {
    view_ticket: 'Xem vé',
    event_type: 'Loại đăng ký',
    event_seat: 'Ghế',
    event_date: 'Ngày',
    event_time: 'Thời gian',
    event_location: 'Địa điểm',
    ticket_id: 'Mã vé',
    ticket_description: 'Quét mã QR này hoặc xuất trình vé này tại buổi hòa nhạc',
    ticket_download: 'Tải xuống',
  },
  errors: {
    error: 'Lỗi',
    required: 'Trường này là bắt buộc',
    invalid_email: 'Email không hợp lệ',
    invalid_phone: 'Số điện thoại không hợp lệ',
  },
  changeLanguage: {
    change_language: 'Thay đổi ngôn ngữ',
    language: 'Ngôn ngữ',
    english: 'Tiếng Anh',
    vietnamese: 'Tiếng Việt',
    done: 'Xong',
    choose_language: 'Chọn ngôn ngữ',
  },
  settings: {
    settings: 'Cài đặt',
    primacy_city: 'Thành phố ưu tiên',
    copy_to_calendar: 'Sao chép sự kiện vào lịch',
    language: 'Ngôn ngữ',
    change_password: 'Đổi mật khẩu',
    support: 'Hỗ trợ',
    help_center: 'Trung tâm trợ giúp',
    terms_of_service: 'Điều khoản dịch vụ',
    logout: 'Đăng xuất',
  },
  logout: {
    logout: 'Đăng xuất',
    logout_description: 'Bạn có chắc chắn muốn đăng xuất?',
    cancel: 'Hủy',
    ok: 'Ok',
  },
  eventDetail: {
    event_detail: 'Chi tiết sự kiện',
    remaining_registration_time: 'Thời gian đăng ký còn lại',
    free: 'Miễn phí',
    view_map: 'Xem bản đồ',
    participants: 'người tham gia',
    about_event: 'Thông tin sự kiện',
    eligibility: 'Điều kiện tham gia',
    other_events: 'Sự kiện khác',
    view_all: 'Xem tất cả',
    join_now: 'Tham gia ngay',
    expired: 'Đã hết hạn',
    gender_rules: 'Giới tính',
    age_rules: 'Độ tuổi',
    all: 'Tất cả',
    not_eligible: 'Không đủ điều kiện',
    attended: 'Đã tham gia',
  },
  gender: {
    male: 'Nam',
    female: 'Nữ',
    other: 'Khác',
  },
  ruleAge: {
    child: 'Trẻ em',
    young_adults: 'Thanh niên',
    middle_aged_adults: 'Trung niên',
    old_aged_adults: 'Người cao tuổi',
  },
  discover: {
    discover: 'Khám phá',
    music: 'Âm nhạc',
    art: 'Nghệ thuật',
    workshop: 'Workshop',
    exhibition: 'Triển lãm',
    priority_event: 'Sự kiện nổi bật',
    near_event: 'Sự kiện gần bạn',
    view_all: 'Xem tất cả',
    search: 'Tìm kiếm',
    filter: 'Bộ lọc',
    location: 'Địa điểm',
    categories: 'Danh mục',
    all: 'Tất cả',
    festival: 'Lễ hội',
    date: 'Ngày',
    start_date: 'Ngày bắt đầu',
    end_date: 'Ngày kết thúc',
  },
  events: {
    events: 'Sự kiện',
    event: 'Sự kiện',
    upcoming: 'Sắp diễn ra',
    history: 'Lịch sử',
    all: 'Tất cả',
    joined: 'Đã tham gia',
    favorite: 'Yêu thích',
    event_gate: 'Gate',
    event_check_in: 'Check in',
  },
  contact: {
    add_contact: 'Lưu liên hệ',
    add_contact_success: 'Lưu liên hệ thành công',
    add_contact_failed: 'Lưu liên hệ thất bại',
    saved: 'Đã lưu',
    company_intro: 'Giới thiệu công ty',
  },
  share: {
    messenger: 'Messenger',
    facebook: 'Facebook',
    instagram: 'Instagram',
    sms: 'SMS',
    email: 'Email',
    copy: 'Copy',
    more: 'More',
  },
  checkIn: {
    scan_to_check_in: 'Quét mã QR để check in',
    check_in_success: 'Check in thành công',
    check_in_failed: 'Check in thất bại',
    event_not_start: 'Sự kiện chưa bắt đầu',
    event_expired: 'Sự kiện đã kết thúc',
    qr_code_not_for_this_event: 'This QR code is not for this event',
    confirm_info: 'Xác nhận thông tin',
    place_qr_code_inside_area: 'Đưa mã QR vào trong khung',
  }
}