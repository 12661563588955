import { Loading } from '@/components/loading'
import { modalRef } from "@/utils/refs"
import Menu from './components/menu'
import Modal from './components/modal'
import useStyles from "./styles"

interface WrapperProps {
  children: React.ReactNode
  className?: string,
  loadingDisabled?: boolean
}

export const Wrapper = (props: WrapperProps) => {
  const { children, loadingDisabled } = props
  const styles = useStyles()

  const isLoading = false
  if (isLoading && !loadingDisabled) return <Loading />

  return (
    <div className={styles.wrapper}>
      {children}
      <Menu />
      <Modal ref={modalRef} />
    </div>
  )
}
