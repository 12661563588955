const { location: { host } } = window

const production = {
  ENV: 'production',
  SERVER_URL: 'https://api.viethub.net',
  WEB_URL: 'https://gate.viethub.vn',
  IMAGE_LINK: 'https://cdn.locaworld.net/vhub/prod',
  ECK: 'Viethub@839P'
}

const uat = {
  ENV: 'uat',
  SERVER_URL: 'https://dev-api.viethub.net',
  WEB_URL: 'https://dev-gate.viethub.net',
  IMAGE_LINK: 'https://cdn.locaworld.net/vhub/staging',
  ECK: 'Viethub@938U'
}

const dev = {
  ENV: 'dev',
  SERVER_URL: 'https://dev-api.viethub.net',
  WEB_URL: 'http://localhost:6888',
  IMAGE_LINK: 'https://cdn.locaworld.net/vhub/staging',
  ECK: 'Viethub@938U'
}

export const envConfigs = host.indexOf('dev-gate.viethub.net') >= 0 ? uat : (host.indexOf('gate.viethub.vn') >= 0 ? production : dev)