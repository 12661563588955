import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { EventState } from './model'
import {
  setEvent,
  setEventTicket,
  setEvents,
  setLoading,
  setParticipants
} from './service'

const useEventStore = create<EventState>()(
  devtools(immer((set, get) => ({
    loading: false,
    event: null,
    events: [],
    eventTicket: null,
    participants: [],
    setLoading,
    setEvent,
    setEvents,
    setEventTicket,
    setParticipants,
  })))
)

export default useEventStore
