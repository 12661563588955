import { useTranslation } from 'react-i18next';
import useStyles from './styles';

export const ScanOverlay = () => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <div className={styles.svgContainerStyle}>
      <div className={styles.svgDesc}>{t('checkIn.place_qr_code_inside_area')}</div>

      <svg
        viewBox="0 0 100 100"
        className={styles.svgStyle}
      >
        <path
          fill="none"
          d="M23,0 L0,0 L0,23"
          stroke="#F84C0B"
          strokeWidth="2"
          strokeLinejoin="round" // Sử dụng bo góc cho đường viền
        ></path>
        <path
          fill="none"
          d="M0,77 L0,100 L23,100"
          stroke="#F84C0B"
          strokeWidth="2"
          strokeLinejoin="round" // Sử dụng bo góc cho đường viền
        ></path>
        <path
          fill="none"
          d="M77,100 L100,100 L100,77"
          stroke="#F84C0B"
          strokeWidth="2"
          strokeLinejoin="round" // Sử dụng bo góc cho đường viền
        ></path>
        <path
          fill="none"
          d="M100,23 L100,0 77,0"
          stroke="#F84C0B"
          strokeWidth="2"
          strokeLinejoin="round" // Sử dụng bo góc cho đường viền
        ></path>
      </svg>
    </div>
  )
}