import gateway, { APIMethod } from "../gateway"

import { envConfigs } from "@/configs"

const API_SERVER = envConfigs.SERVER_URL

export const getEvents = (filter?: any) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/events/gate-users-events`, filter)
}

export const getEvent = (eventId: string) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/p/events/${eventId}`)
}

export const getEventTicket = (eventId: string, ticketId: string) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/events/${eventId}/user-participants/${ticketId}`)
}

export const checkIn = (data: any) => {
  const { eventId, ...params } = data
  return gateway.send(APIMethod.PUT, `${API_SERVER}/events/${eventId}/user-participants/update-status`, params)
}

export const getParticipants = (params: any) => {
  const { eventId, ...query } = params
  return gateway.send(APIMethod.GET, `${API_SERVER}/events/${eventId}/event-participants`, query)
}