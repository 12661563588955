
import { Loading, Wrapper } from '@/components'

import images from '@/assets'
import EmptyState from '@/components/empty-state'
import { EventSuggestCard } from '@/components/event-suggest-card'
import Header from '@/components/wrapper/components/header'
import { BreadcrumbsConstant } from '@/constants'
import { useAppStore } from '@/stores/app'
import { EventAPI } from '@/stores/event'
import EventModel from '@/stores/event/model'
import useEventStore from '@/stores/event/state'
import useUserStore from '@/stores/user/state'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoSearchOutline } from 'react-icons/io5'
import useStyles from './styles'

const Home = () => {
  const { t } = useTranslation()
  const styles = useStyles()

  const { user } = useUserStore()
  const { events, setEvents } = useEventStore()
  const { changeBreadcrumb } = useAppStore()

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    changeBreadcrumb(BreadcrumbsConstant.Home)
  }, [])

  useEffect(() => {
    if (user) {
      getEvents()
    }
  }, [user])

  const getEvents = async () => {
    setLoading(true)
    const response: any = await EventAPI.getEvents()
    if (response.status === 200) {
      const events = response?.data?.data?.items?.map((event: any) => new EventModel(event))
      setEvents(events)
    }
    setLoading(false)
  }

  return (
    <Wrapper>
      <Header title={t('events.events')} />

      {loading && <Loading />}

      <div className={styles.container}>
        <section className={styles.sectionSearch}>
          <span className={styles.searchIcon}>
            <IoSearchOutline size={24} />
          </span>
          <input type="text" className={styles.input} name="keywords" placeholder={t('discover.search')} />
        </section>

        <section className={styles.sectionEvent}>
          <div className={styles.rowTitle}>
            <div className={styles.rowLabel}>{t('events.events')}</div>
          </div>

          <div className={styles.rowContentSuggest}>
            {events?.length > 0 ? events?.map((event: any) => (
              <EventSuggestCard key={event?.id} event={event} />
            )) :
              <EmptyState
                text={t('dataNotFound')}
                imgUrl={images.dataNotFound()}
              />
            }
          </div>
        </section>
      </div>
    </Wrapper>
  )
}

export default Home
