import gateway, { APIMethod, parseFormData } from "../gateway"

import { envConfigs } from "@/configs"

const API_SERVER = envConfigs.SERVER_URL

export const login = (params: any) => {
  return gateway.send(APIMethod.POST, `${API_SERVER}/gate-users/sign-in`, params)
}

export const signup = (params: any) => {
  return gateway.send(APIMethod.POST, `${API_SERVER}/users/sign-up`, params)
}

export const loginWithFacebook = (params: any) => {
  return gateway.send(APIMethod.POST, `${API_SERVER}/users/facebook`, params)
}

export const loginWithGoogle = (params: any) => {
  return gateway.send(APIMethod.POST, `${API_SERVER}/users/google`, params)
}

export const sendForgetPasswordEmailOtp = (params: any) => {
  return gateway.send(APIMethod.POST, `${API_SERVER}/users/send-reset-password-mail`, params)
}

export const checkEmailOtp = (params: any) => {
  return gateway.send(APIMethod.POST, `${API_SERVER}/users/check-user-otp`, params)
}

export const getUserProfile = () => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/gate-users/profile`)
}

export const getUserDetail = (userId: string) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/users/${userId}`)
}

export const getUsers = () => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/users`)
}

export const updateUserAvatar = (userId: number, file: any) => {
  return gateway.send(
    APIMethod.PUT,
    `${API_SERVER}/users/${userId}/change-avatar`,
    parseFormData({ file }),
    { 'Content-Type': 'multipart/form-data' }
  )
}

export const saveUser = (params: any) => {
  return gateway.send(APIMethod.POST, `${API_SERVER}/users`, params)
}

export const changePasswordUser = (userId: string, params: any) => {
  return gateway.send(
    APIMethod.PUT,
    `${API_SERVER}/users/${userId}/change-password`,
    params
  )
}

export const updateUser = (userId: number, data: any, files: any) => {
  const formData: any = parseFormData(data)
  if (files) {
    formData.append('files', files)
  }

  return gateway.send(
    APIMethod.PUT,
    `${API_SERVER}/users/${userId}`,
    formData,
    { 'Content-Type': 'multipart/form-data' }
  )
}

export const deleteUser = (userId: number) => {
  return gateway.send(APIMethod.DELETE, `${API_SERVER}/users/${userId}`)
}


export const refreshToken = (params: any) => {
  return gateway.send(APIMethod.PUT, `${API_SERVER}/users/refresh-token`, params)
}

export const resetPassword = (params: any) => {
  return gateway.send(APIMethod.PUT, `${API_SERVER}/users/reset-user-password`, params)
}

export const updateProfile = (params: any) => {
  return gateway.send(APIMethod.PUT, `${API_SERVER}/users/update-profile`, params)
}
