import { createContext, ReactNode, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import Modal from 'react-modal';
import useStyles from './ModalContextStyles';

Modal.setAppElement('#root'); // Đặt phần tử gốc cho Modal

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    maxWidth: '800px',
    minWidth: '300px',
    margin: '0 auto',
    zIndex: 1000
  }
}

// Định nghĩa kiểu dữ liệu cho modal context
interface ModalContextType {
  showModal: (content: ReactNode, confirmButton?: ReactNode, title?: ReactNode) => void;
  hideModal: () => void;
  isModalOpen: boolean;
  modalContent: ReactNode | null;
  modalConfirmButton: ReactNode | null;
}

// Tạo context
const ModalContext = createContext<ModalContextType | undefined>(undefined);

// Tạo hook để sử dụng context
export function useModal() {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
}

// Tạo ModalProvider
interface ModalProviderProps {
  children: ReactNode;
}

export function ModalProvider({ children }: ModalProviderProps) {
  const { t } = useTranslation()
  const styles = useStyles()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState<ReactNode | null>(null);
  const [modalContent, setModalContent] = useState<ReactNode | null>(null);
  const [modalConfirmButton, setModalConfirmButton] = useState<ReactNode | null>(null);

  const showModal = (content: ReactNode, confirmButton?: ReactNode, title?: ReactNode) => {
    setModalTitle(title || null);
    setModalContent(content);
    setModalConfirmButton(confirmButton || null);
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
    setModalTitle(null);
    setModalContent(null);
    setModalConfirmButton(null);
  };

  return (
    <ModalContext.Provider value={{ showModal, hideModal, isModalOpen, modalContent, modalConfirmButton }}>
      {children}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={hideModal}
        contentLabel="Modal"
        style={customStyles}
        ariaHideApp={false}
      >
        <div className={styles.modalContent}>
          <div className={styles.modalHeader}>
            <div className={styles.modalTitle}>
              {modalTitle || t('alert.alert')}
            </div>
            <div className={styles.close} onClick={hideModal}>
              <AiOutlineClose size={18} style={{ color: '#8A8380' }} />
            </div>
          </div>
          <div className={styles.modalBody}>
            {modalContent}
          </div>
          {modalConfirmButton && <div className={styles.modalFooter}>{modalConfirmButton}</div>}
        </div>
      </Modal>
    </ModalContext.Provider>
  );
}