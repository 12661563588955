

import { BreadcrumbsConstant, RoutesUrl } from '@/constants';
import { useAppStore } from '@/stores/app';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidUser, BiUser } from 'react-icons/bi';
import { RiHomeFill, RiHomeLine, RiSettingsFill, RiSettingsLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import './styles.css';


export enum Language {
  English = 'en',
  Vietnamese = 'vi'
}

const MenuCard = React.memo((props: any) => {
  const { title, url, active, icActive, icInActive } = props

  return (
    <li className={active ? 'active' : ''}>
      <Link to={url}>
        {!active ? icInActive : icActive}
        <span></span>
      </Link>
    </li>
  )
})

const Menu = () => {
  const { t } = useTranslation()
  const { breadcrumb } = useAppStore()

  const menus = [
    {
      title: t('home.home'),
      url: RoutesUrl.Home,
      active: Number(breadcrumb) === BreadcrumbsConstant.Home,
      icActive: <RiHomeFill size={22} />,
      icInActive: <RiHomeLine size={22} />
    },    
    {
      title: t('home.settings'),
      url: RoutesUrl.Settings,
      active: Number(breadcrumb) === BreadcrumbsConstant.Settings,
      icActive: <RiSettingsFill size={22} />,
      icInActive: <RiSettingsLine size={22} />
    },
  ]

  return (
    <nav className="menu-nav">
      <ul className="menu">
        {
          menus.map((menu: any) => (
            <MenuCard key={menu.url} {...menu} />
          ))
        }
      </ul>
    </nav>
  )
}

export default Menu
