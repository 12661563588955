import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  //css qr scanner
  qrScannerContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },

  qrReaderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div > video': {
      width: 'auto !important',
      height: '100% !important',
      overflow: 'unset !important',
      objectFit: 'contain !important',
      '@media (max-width: 768px)': {
        width: '100vw !important',
        height: '100vh !important',
        objectFit: 'cover !important',
      },
    },
  },

  //btn confirm info
  btnGroupConfirm: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > button': {
      width: '48%',
      margin: '0 !important',
    },
  },

  btnOk: {
    width: '65%',
    height: '40px',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: Colors.PRIMARY,
    color: '#fff',
    marginTop: '15px',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
    '&:hover': {
      backgroundColor: Colors.PRIMARY,
      color: '#fff',
    },
  },

  btnCancel: {
    width: '65%',
    height: '40px',
    border: `1px solid ${Colors.PRIMARY}`,
    borderRadius: '8px',
    backgroundColor: '#fff',
    color: Colors.PRIMARY,
    marginTop: '15px',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
    '&:hover': {
      backgroundColor: '#fff',
      color: Colors.PRIMARY,
    },
  },
})

export default useStyles
