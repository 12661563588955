import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  //css container
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1, ///height: vh
    padding: '20px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.25em'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgb(223 227 233)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bcbcbc',
    },
    '@media (max-width: 768px)': {

    },
  },

  ticket: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '15px',
  },

  ticketInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  eventBanner: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#FFC1AA1A',
    borderRadius: '10px 10px 0 0',
  },

  banner: {
    display: 'flex',
    width: '100%',
    objectFit: 'cover',
    borderRadius: '1.5rem',
    padding: '15px',
  },

  eventInfo: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 15px 15px',
    width: '100%',
    background: '#FFC1AA1A',
    backgroundRepeat: 'no-repeat',
  },

  eventName: {
    fontSize: '20px',
    fontWeight: '500',
    marginBottom: '10px',
  },

  label: {
    fontSize: '12px',
    fontWeight: '400',
    marginBottom: '5px',
    color: '#8A8380',
  },

  value: {
    fontSize: '14px',
    fontWeight: '500',
    marginBottom: '10px',
  },

  eventTypeSeat: {
    display: 'flex',
    flexDirection: 'row',
  },

  eventType: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginRight: '10px',
  },

  eventSeat: {
    display: 'flex',
    flexDirection: 'column',
    width: '75px'
  },

  groupInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  eventDateTime: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '@media (max-width: 768px)': {
      flex: 'inherit',
    },
  },

  eventDate: {
    flex: 1,
  },

  eventTime: {
    width: '75px'
  },

  eventLocation: {
    marginBottom: '10px',
  },

  btnCheckIn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '250px',
    height: '250px',
    borderRadius: '10px',
    cursor: 'pointer',
    padding: '20px',
    '@media (max-width: 768px)': {
      width: '100%',
      height: '100%',
    },
  },

  qrCode: {
    width: '30px',
    height: '30px',
  },

  txtScan: {
    fontSize: '14px',
    fontWeight: '500',
    marginTop: '5px',
    textAlign: 'center',
  },

  //participants
  participants: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '20px',
    flexWrap: 'wrap',
  },

  participantsTitle: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '5px',
  },

  title: {
    fontSize: '1rem',
    fontWeight: '500',
    marginBottom: '10px',
  },

  number: {
    fontSize: '1rem',
    fontWeight: '500',
    marginBottom: '10px',
    color: '#8A8380',
    marginLeft: '10px',
  },

  participantsList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  participant: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '120px',
    borderRadius: '10px',
    border: '1px solid #E6E6E6',
    marginBottom: '10px',
  },

  participantAvatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundColor: '#FFC1AA1A',
    marginRight: '10px',
  },

  participantInfo: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginLeft: '10px',
  },

  participantName: {
    fontSize: '14px',
    fontWeight: '500',
    marginBottom: '5px',
  },

  mr10: {
    marginRight: '10px',
    marginBottom: '10px',
  },
})

export default useStyles
